/*
 * 업무구분: 계약관리
 * 화 면 명: MSPPM871P
 * 화면설명: 유지체크명세 상세조회조건
 * 접근권한: 
 * 작 성 일: 2022.12.23
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" :show-title="true" title="유지체크명세 상세조건" type="subpage">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <!-- 조직관련 COMPONENT : 관리자 접근시 노출 -->
        <ur-box-container v-if="lv_IsMngr" direction="column" alignV="start" class="ns-sch-area">
          <SearchCondOrgCM ref="refSearchOrg" v-model="lv_OrgSearchData" :isFofType="1" :pOrgData="lv_OrgSearchData" @selectResult="fn_SlectCnsltResultPM"/>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-input-box mt15 mb50">
          <mo-text-field ref="error" v-model="lv_SearchCustNm" underline placeholder="고객명을 입력해주세요" clearable/>  
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="ns-input-box mb50">
          <span class="ns-title">유지횟수</span>
           <div class="form-add-box txt-add-area">
            <div class="txt-add-box">
              <mo-text-field  class="form-input-name" v-model="sel_group1" underline placeholder="2" />
              <span class="input-txt">회</span>
            </div>
            <span class="txt-btween">~</span>
            <div class="txt-add-box">
              <mo-text-field  class="form-input-name" v-model="sel_group2" underline placeholder="49" />
              <span class="input-txt">회</span>
            </div>
          </div>
        </ur-box-container>
      
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-input-box mb50">
          <span class="ns-title mb10">산출기준</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_InqrStndCd" class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in lv_InqrStndCds" :value="item.value" :key="idx">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_009" direction="column" class="ns-input-box mb50">
          <span class="ns-title">납입상태</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_StatScCd" class="chip-type-wrap chip-ty1">
              <mo-segment-button v-for="(item, idx) in lv_StatScCds" :value="item.value" :key="idx">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-input-box mb50">
          <span class="ns-title">상품구분</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_PrdtLclsfCd" class="chip-type-wrap chip-ty1">
              <mo-segment-button v-for="(item, idx) in lv_PrdtLclsfCds" :value="item.value" :key="idx" @click="fn_CheckUni(item)">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_013" direction="column" class="ns-input-box mb50">
          <span class="ns-title">납입방법</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_InqrScCd" class="chip-type-wrap chip-ty1">
              <mo-segment-button v-for="(item, idx) in lv_InqrScCds" :value="item.value" :key="idx">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        
        <ur-box-container alignV="start" componentid="ur_box_container_013" direction="column" class="ns-input-box mb100">
          <span class="ns-title">이체일</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_trsfDdScVal" class="chip-type-wrap chip-ty1 ">
              <mo-segment-button v-for="(item, idx) in lv_trsfDdScVals" :value="item.value" :key="idx">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
    
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ResetBtnClick">초기화</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ValidationCheck">조회</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>

    <!-- 바텀시트1 : 유니버셜 상품 고객안내 주의 바텀시트 입니다. -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet closebtn">
      <template v-slot:title>유니버셜 상품 고객 안내 주의</template>
      <div class="ns-btn-close" @click="fn_InitBottomSheet"></div>  <!-- close 버튼 Sample -->
      <!-- content 영역 -->
      <div class="content-area ns-ftcr-gray">
         <p class="txt-ic"><span class="ic">※</span> 의무납입 기간 경과 후 보험료 납입여부는 계약상세조회 후 "최종납입일자" 확인 하여 고객 안내 바랍니다.</p>
         <p class="pl20">유니버셜 상품은 당월에 월대체보험료가 발생 될 경우 유지율 반영됩니다.</p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InitBottomSheet" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
    <ur-box-container alignV="start" componentid="ur_box_container_033" direction="column">
      <div class="ns-height40"></div>
    </ur-box-container>
    <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 //-->
  </ur-page-container>
</template>

<script>
/*******************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ******************************************************************************/
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import pmConstants from '@/config/constants/pmConstants.js'

export default {
  /*****************************************************************************
   * Vue 파일 속성 정의 영역
   ****************************************************************************/
  name: 'MSPPM871P',
  screenId: 'MSPPM871P',
  components: {
    SearchCondOrgCM,
    MspBottomSelect
  },
  props: {
    pIsMngr: Boolean, // 조직combox 권한자여부
    pOrgData: {
      type: Object,
      default: {}
    },
    pSearchCustNm: String, // 계약자명
    pSel_group1: String, // 유지횟수 시작회차
    pSel_group2: String, // 종료회차
    pInqrStndCd: String,  // 산출기준
    pStatScCd: String,  // 납입상태
    pPrdtLclsfCd: String,  // 상품구분
    pInqrScCd: String,  // 납입방법
    pTrsfDdScVal: String,  // 이체일
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /*****************************************************************************
   * Life Cycle 함수 정의 영역
   ****************************************************************************/
  created () {
    // post 호출 전 Progress 타입 설정
    window.vue.getStore('progress').dispatch('FULL')

    this.userInfo = this.getStore('userInfo').getters.getUserInfo

    // 부모화면으로부터 전달받은 파라미터를 설정
    this.lv_IsMngr = this.$props.pIsMngr // 관리자여부
    this.lv_OrgSearchData = _.cloneDeep(this.$props.pOrgData) // 조직정보
    this.lv_SearchCustNm = _.cloneDeep(this.$props.pSearchCustNm) // 계약자명
    this.sel_group1 = _.cloneDeep(this.$props.pSel_group1) 
    this.sel_group2 = _.cloneDeep(this.$props.pSel_group2)
    this.lv_InqrStndCd = _.cloneDeep(this.$props.pInqrStndCd) // 산출기준
    this.lv_StatScCd = _.cloneDeep(this.$props.pStatScCd) // 납입상태
    this.lv_PrdtLclsfCd = _.cloneDeep(this.$props.pPrdtLclsfCd) // 상품구분
    this.lv_InqrScCd = _.cloneDeep(this.$props.pInqrScCd) // 납입방법
    this.lv_trsfDdScVal = _.cloneDeep(this.$props.pTrsfDdScVal) // 이체일
  },
  mounted () {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /*****************************************************************************
   * 화면 전역 변수 선언 영역
   ****************************************************************************/
  data () {
    return {
      userInfo: {},
      lv_ReturnStr: [], // 조회조건 텍스트 배열
      lv_SearchCustNm: '', // 계약자명

      // 유지횟수
      selectGroups: {
        group1: pmConstants.INQR_STR_TYPE_CD,
        group2: pmConstants.INQR_END_TYPE_CD,
      },
      sel_group1: '2',
      sel_group2: '49',
      // 산출기준
      lv_InqrStndCd : '0',
      lv_InqrStndCds: pmConstants.ESTIMATE_TYPE_CD,
      // 납입상태
      lv_StatScCd : '3',
      lv_StatScCds: pmConstants.MONEY_RECIEVED_TYPE_CD,
      // 상품구분
      lv_PrdtLclsfCd : '99',
      lv_PrdtLclsfCds: pmConstants.SERVICE_GOODS_TYPE_CD,
      // 납입방법
      lv_InqrScCd : '99',
      lv_InqrScCds: pmConstants.PAYMENT_MTH_TYPE_CD,
      // 이체일
      lv_trsfDdScVal : '00',
      lv_trsfDdScVals: pmConstants.REGULAR_PAY_TYPE_CD,
      isShowCnslt: false, // 담당컨설턴트
      allChk: false, // 조직, 담당컨설턴트 조회완료 여부
      lv_OrgSearchData: {}, // 조직정보
      lv_RtnOrgSearchData: {}, // 조직정보
      lv_IsMngr: false, // 관리자여부
      lv_AlertPop: '' // bottom alert component
    }
  },
  /*****************************************************************************
   * 사용자 함수 정의 영역
   ****************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SlectCnsltResultPM()
     * 설명       : 조직 변경시, 전역변수에 셋팅
     *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
     * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
     * allChk: 조회버튼 활성화/비활성화 여부
     ******************************************************************************/
    fn_SlectCnsltResultPM (pData) {
      if ( this.isShowCnslt ) {
        // this.lv_OrgSearchData = pData
        this.lv_RtnOrgSearchData = pData
        this.allChk = true
      }
      this.isShowCnslt = true
    },

    /******************************************************************************
     * Function명 : fn_CheckUni
     * 설명       : 유니버셜 상품 체크
     ******************************************************************************/
    fn_CheckUni(item) {
      let lv_Vm = this
      
      // 상품구분 - 유니버셜 선택 시
      if (item.value === "5") {
        lv_Vm.$refs.nsbottomsheet.open()
      } 

    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet() {
      let lv_Vm = this
      
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.nsbottomsheet !== undefined) {
          lv_Vm.$refs.nsbottomsheet.close()
        }
      } 

    },

    /******************************************************************************
     * Function명 : fn_ResetBtnClick
     * 설명       : 상세조회 조건 초기화
     *              (상세조회 내 초기화 버튼 클릭시 호출)
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_ResetBtnClick (e) {
      // 조직
      // 관리자 접근 시 조직정보 초기화 후, 재조회
      this.lv_OrgSearchData = {}
      this.lv_RtnOrgSearchData = {}
      if (this.pIsMngr) this.$refs.refSearchOrg.fn_GetFofRol()

       // 계약자명 검색어 초기화
      this.lv_SearchCustNm = ''
      // 유지횟수 초기화
      this.sel_group1 = '2'
      this.sel_group2 = '49'
      this.lv_InqrStndCd = this.lv_InqrStndCds[0].value // 산출기준 초기화
      this.lv_StatScCd = this.lv_StatScCds[2].value // 납입상태 초기화
      this.lv_PrdtLclsfCd = this.lv_PrdtLclsfCds[0].value // 상품구분 초기화
      this.lv_InqrScCd = this.lv_InqrScCds[0].value // 납입방법 초기화
      this.lv_trsfDdScVal = this.lv_trsfDdScVals[0].value // 이체일 초기화
    },

    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 상세조회조건을 설정 후 부모화면에의 조회함수 호출
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_Search () {
      // 초기화 여부
      let isInit = false
      if (this.$bizUtil.isEmpty(this.lv_SearchCustNm) && this.sel_group1 === '2' && this.sel_group2 === '49' && this.lv_InqrStndCd === this.lv_InqrStndCds[0].value
          && this.lv_StatScCd === this.lv_StatScCds[2].value && this.lv_PrdtLclsfCd === this.lv_PrdtLclsfCds[0].value && this.lv_InqrScCd === this.lv_InqrScCds[0].value && this.lv_trsfDdScVal === this.lv_trsfDdScVals[0].value) {
        if (this.lv_IsMngr) {
          if (this.userInfo.userId === this.lv_RtnOrgSearchData.cnslt.key) isInit = true
          else isInit = false
        } else isInit = true
      }

      // 조회조건 텍스트 배열
      this.lv_ReturnStr = [] // 초기화
      // 조회조건: 고객명, 유지횟수, 산출기준, 납입상태, 상품구분, 납입방법, 이체일
      this.lv_ReturnStr = [this.lv_SearchCustNm, this.sel_group1, this.sel_group2, this.lv_InqrStndCds.find(e => e.value === this.lv_InqrStndCd).text,
                            this.lv_StatScCds.find(e => e.value === this.lv_StatScCd).text, this.lv_PrdtLclsfCds.find(e => e.value === this.lv_PrdtLclsfCd).text,
                            this.lv_InqrScCds.find(e => e.value === this.lv_InqrScCd).text, this.lv_trsfDdScVals.find(e => e.value === this.lv_trsfDdScVal).text]

      // return data set
      let t_RtnData = {
        pIsMngr: this.lv_IsMngr, // 관리자여부
        // pOrgData: _.cloneDeep(this.lv_OrgSearchData), // 조직정보
        pOrgData: _.cloneDeep(this.lv_RtnOrgSearchData),
        searchCustNm: this.lv_SearchCustNm, // 계약자명
        sel_group1: this.sel_group1,  // 유지횟수
        sel_group2: this.sel_group2,
        inqrStndCd: this.lv_InqrStndCd, // 산출기준
        statScCd: this.lv_StatScCd, // 납입상태
        prdtLclsfCd: this.lv_PrdtLclsfCd, // 상품구분
        inqrScCd: this.lv_InqrScCd, // 납입방법
        trsfDdScVal: this.lv_trsfDdScVal, // 이체일
      }
      this.$emit('onPopupSearch', {rtnData: t_RtnData, srchOpt: this.lv_ReturnStr, isInit: isInit})
    },

    /******************************************************************************
     * Function명 : fn_ValidationCheck
     * 설명       : 상세조회조건 유효성 체크
     ******************************************************************************/
    fn_ValidationCheck () {
      if (this.sel_group1 < 2 || this.sel_group1 > 49 || this.sel_group2 < 2 || this.sel_group2 > 49) {
        this.getStore('confirm').dispatch('ADD', '유지횟수는 2회에서 49회까지 입력 가능합니다.')
        return
      }
      
      if (Number(this.sel_group1) > Number(this.sel_group2)) {
        this.getStore('confirm').dispatch('ADD', '조회 시작횟수는 조회 종료횟수 보다 클 수 없습니다.')
        return
      }
      // 조직 콤보박스 권한자인 경우 컨설턴트 입력여부 체크
      if ( this.lv_IsMngr ) {
        if ( _.isEmpty(this.lv_RtnOrgSearchData) || this.lv_RtnOrgSearchData.cnslt.length === 0 ||
          this.lv_RtnOrgSearchData.cnslt.key === undefined ||
          this.lv_RtnOrgSearchData.cnslt.key === null ||
          this.lv_RtnOrgSearchData.cnslt.key === '1' ||
          this.lv_RtnOrgSearchData.cnslt.key === '0' ) {
          this.getStore('confirm').dispatch('ADD', '정보보호 요청 대상건 입니다.')  // EPMC005
          return
        }
      }

      this.fn_Search()
    },

    /******************************************************************************
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     ******************************************************************************/
    fn_BottomAlert (title, pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: title,
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    }
  }
}
</script>
